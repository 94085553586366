import React, { Component } from "react";
import Slider from "react-slick";

export default class SafeData extends Component {
  render() {
    const settings = {
      dots: false,
      fade: true,
      arrows: false,
      pauseOnHover: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 300,
      autoplaySpeed: 7500,
      cssEase: "linear"
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
          <div className="blue-block heighto">
            <h4>Network security</h4>
            <br />
            <p>Our dedicated network security systems protect against DDoS and man-in-the-middle attacks and use reputable registrars to safeguard against domain hijacking and phishing attacks.</p>
            </div>
          </div>
          <div>
          <div className="blue-block heighto">
            <h4>Encryption</h4>
            <br />
            <p>All network traffic and confidential information are encrypted in line with best practices of encryption key storage and security.</p>
          </div>
          </div>
          <div>
          <div className="blue-block heighto">
            <h4>Safeguarded Accounts</h4>
            <br />
            <p>To protect your money, our regulated partner payment
system providers hold it in their accounts. Thus protecting you in the unlikely event of
CurrencyCloud ceasing to exist.</p>
          </div>
          </div>
        </Slider>
      </div>
    );
  }
}