import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StockGainer from "../components/StockGainer";
import ChartTwo from "../components/ChartTwo";
import ChartBandLight from "../components/ChartBandLight";
import SafeData from "../components/SafeData";
import TrustPilot from "../components/TrustPilot";

export const BusinessPageTemplate = ({ image, title, heading, subheading, mainpitch, description, intro }) => (
  <div>
    <Helmet>
      <title>Swiftfox | Business</title>
    </Helmet>
    <div className="hero-section business-hero">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Complete Control of International Business Finance Transfers
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  We’re simplifying business in a multi-currency world. Use Swiftfox to quickly access virtual named accounts for your customers and collect, convert, pay and manage multiple currencies simultaneously - anywhere in the world. 
                  <br/><br/>
                  A Corporate Swiftfox account provides you access to an expert FX advisor, who will help you maximise your returns when exchanging currency and sending international payments.


                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <a className="main-button-alt" href="https://onboarding.paydirect.io/swiftfox/forms/corporate" target="_blank" rel="noreferrer">
                  Open your account today
                  </a>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <div className="hero-banner-light">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }} className="hero-banner-ticker">
                <ChartBandLight />
            </motion.div>
          )}
        </InView>
      </div>
      <div className="hero-video-overlay" />
      <div className="hero-video">
        <video loop autoPlay muted playsInline>
            <source src="/img/sf-business.webm" type="video/webm" />
            <source src="/img/sf-business.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
    <div className="callout-section white-bg dark-text" id="get-started">
      <div className="callout-main nogapok">
        <div className="callout-main-content droppit">
          <div className="section-item">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Why Swiftfox?</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  Whether your business is looking at lucrative new income streams or improving international relationships, our smart payment solutions and FX capabilities make it easier for you and your customers to move money internationally. 
                </motion.h4>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  Your business account can receive funds via SWIFT or with the named virtual account to efficiently collect and hold all non-restricted currencies. Then, you can decide when to convert these funds back to your required currency.
                </motion.h4>
              )}
            </InView>
          </div>
        </div>
        <div className="callout-main-content">
          <div className="section-item white-text">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button-alt" to="open-an-account">
                  Get started
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section dark-bg white-text">
      <div className="callout-main lessgapok">



        <div className="callout-main-content largerones lessgapwide">
            <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/bbr.svg" />
              <div className="fadeybox">
                <InView threshold={0.1}>
                  {({ ref, inView }) => (
                    <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                      <span>Bank Beating Rates</span>
                    </motion.h2>
                  )}
                </InView>
                <InView threshold={0.1}>
                  {({ ref, inView }) => (
                    <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                      Our business customers save money when converting currency with a Swiftfox account compared to conversion rates used by other currency exchange services and high street banks.
                    </motion.h4>
                  )}
                </InView>
              </div>
            </div>
          
          
            <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/regular.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Fast & Secure Payments</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  Currency Cloud Limited offer international payments to over 180 countries.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
        </div>


        <div className="callout-main-content largerones lessgapwide">
        <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/dts.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Dedicated Trader Support </span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  Your dedicated account manager will take the time to understand your needs, providing you with assistance and support to ensure frictionless, competitive and faster transfers.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
          
          <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/ipp.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>International Payments Platform</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  All the tools you need for sending and receiving international payments.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
        </div>






        <div className="callout-main-content largerones lessgapwide">
        <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/tran.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Transparency</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  The mid-market rate is always visible, allowing you to see how competitive our rates are, which can be seen on our ‘Live FX’ webpage.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
          
          <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/tpr.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Five Star Trustpilot Rating</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  We're proud of what our customers say about us and the recognition we receive for the service we provide. You can find our most recent reviews at the bottom of the page.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
        </div>








        <div className="callout-main-content largerones lessgapwide">
        <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/24.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>24-Hour access</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  An account provides you with access to our platform and business tools anywhere any time. 
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
          
          <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/ts.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Trade Status</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  Track your trade status along the payment journey, for complete transparency of your transfers.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
        </div>







      </div>
    </div>
    <div className="callout-section white-bg dark-text" id="get-started">
      <div className="callout-main">
        <div className="callout-main-content switcho">
          <div className="section-item">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="module-stack">
                  <div className="bullet-container">
                    <div className="bullet">
                      <img alt="expanding abroad" src="../img/expo.svg" />
                      <p>Expanding<br/>abroad</p>
                    </div>
                    <div className="bullet">
                      <img alt="Property Investment" src="../img/prop.svg" />
                      <p>Property<br/>investment</p>
                    </div>
                    <div className="bullet">
                      <img alt="Exporting & Importing" src="../img/abroad2.svg" />
                      <p>Exporting &<br/>importing</p>
                    </div>
                    <div className="bullet">
                      <img alt="Regular Payments" src="../img/regular.svg" />
                      <p>Regular<br/>Payments</p>
                    </div>
                    <div className="bullet">
                      <img alt="Hedging Solutions" src="../img/hedge.svg" />
                      <p>Hedging<br/>solutions</p>
                    </div>
                    <div className="bullet">
                      <img alt="Mass Payments" src="../img/cash-w.svg" />
                      <p>Bulk<br/>payments</p>
                    </div>
                    <div className="bullet">
                      <img alt="Regular Payments" src="../img/instant.svg" />
                      <p>Quick<br/>Payments</p>
                    </div>
                    <div className="bullet">
                      <img alt="Hedging Solutions" src="../img/yen.svg" />
                      <p>Multi-currency<br/>solutions</p>
                    </div>
                    <div className="bullet">
                      <img alt="Mass Payments" src="../img/merchant.svg" />
                      <p>Merchant<br/>Accounts</p>
                    </div>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>International payments, made easy</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  Whether purchasing an investment property abroad or buying high-value assets, we ensure that your overseas payments are processed quickly and securely.
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.0, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" to="/open-an-account">
                    Make a transfer
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section  dark-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Check your real-time conversion rate*
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Follow live currency rates at a glance. These tables show real-time bid and ask rates for all currency pairs traded at Swiftfox.
                  <br/><br/>
                  <i>*for illustration purposes only, actual rate may differ</i>


                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" to="/open-an-account">
                  Make a transfer
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.0, ease: "easeInOut" }} className="hero-chart-callout pushmeright sizefixer">
                  <ChartTwo />
                </motion.div>
              )}
            </InView>
          </div>
        </div> 
      </div>
    </div>
    <div className="callout-section orange-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Your Swiftfox currency account
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  A Swiftfox currency account allows you to quickly and securely convert from 37 different currencies.
<br/><br/>
In addition, you’ll receive a unique International Bank Account Number (IBAN), allowing you to send and receive payments seamlessly across borders.

                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" to="/open-an-account">
                  Open an account
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.0, ease: "easeInOut" }} className="module-stack">
                  <div className="bullet-container">
                    <div className="bullet">
                      <img alt="No Hidden Fees" src="../img/fee.svg" />
                      <p>No Hidden Fees</p>
                    </div>
                    <div className="bullet">
                      <img alt="No Contracts" src="../img/contracts.svg" />
                      <p>No Contracts</p>
                    </div>
                    <div className="bullet">
                      <img alt="sNo Payment Fees" src="../img/payment.svg" />
                      <p>No Payment Fees</p>
                    </div>
                    <div className="bullet">
                      <img alt="24/7 Support" src="../img/sup.svg" />
                      <p>24/7<br />Support</p>
                    </div>
                    <div className="bullet">
                      <img alt="Dedicated Account Managers" src="../img/manager.svg" />
                      <p>Dedicated Acc. Managers</p>
                    </div>
                    <div className="bullet">
                      <img alt="Fast & Easy Int. Payments" src="../img/fast.svg" />
                      <p>Fast & Easy Int. Payments</p>
                    </div>
                    <div className="bullet">
                      <img alt="Pre-Saved Beneficiaries" src="../img/saved.svg" />
                      <p>Pre-Saved Beneficiaries</p>
                    </div>
                    <div className="bullet">
                      <img alt="Trade Status & History" src="../img/history.svg" />
                      <p>Trade Status & History</p>
                    </div>
                    <div className="bullet">
                      <img alt="FCA Authorised" src="../img/fca.svg" />
                      <p>FCA<br />Authorised</p>
                    </div>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section dark-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  <span>Additional Exchange Services</span>
                </motion.h2>
              )}
            </InView>
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }} className="button-container">
                  <div className="blue-block section-item">
                    <h4>Spot contracts</h4>
                    <p>Our Spot Contracts allow you to exchange currency instantly. You’ll receive a competitive exchange rate at the time of purchase with a same-day settlement.</p>
                    <Link to="contact-us" className="main-button-alt">
                    Get in touch to learn more
                    </Link>
                  </div>
                  <div className="grey-block section-item">
                    <h4>Forward Contracts</h4>
                    <p>With a Forward Contract, we agree upon an exchange rate that remains fixed for up to one year. They give your business confidence in projecting your financial forecasts, mitigating risk and uncertainty. </p>
                    <Link  to="contact-us" className="main-button-alt">
                    Get in touch to learn more
                    </Link>
                  </div>
                </motion.div>
              )}
            </InView>
        </div>
      </div>
    </div>
    <div className="callout-section white-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="module-stack widtho">
                    <TrustPilot />
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Fast, Reliable and Secure</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  The security and privacy of your data are of the highest importance to us. That’s why we’ve partnered with The Currency Cloud Limited as our payment service provider. They are one of the world's top payment engines, designed to protect the safety and security of customers' money.<br/><br/>
                  Payment services for Swiftfox are provided by The Currency Cloud Limited. Registered in England No. 06323311. Registered Office: Stewardship Building 1st Floor, 12 Steward Street London E1 6FQ. <br/><br/>
                  The Currency Cloud Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 for the issuing of electronic money (FRN: 900199)
                </motion.h4>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section dark-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  <span>Your data, safe & secure</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Currency Cloud's services operate on Amazon Web Services (AWS), certified under several global compliance programmes that underline best practices in terms of data centre security.
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <p><strong>The Currency Cloud Credentials:</strong> <br/>ISO 27001 Information Security Management Controls</p>
                </motion.h4>
              )}
            </InView>
          </div>
          <div className="section-item">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="module-stack widtho">
                    <SafeData />
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>



















  </div>
);

BusinessPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const BusinessPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <BusinessPageTemplate image={frontmatter.image} title={frontmatter.title} heading={frontmatter.heading} subheading={frontmatter.subheading} mainpitch={frontmatter.mainpitch} description={frontmatter.description} intro={frontmatter.intro} />
    </Layout>
  );
};

BusinessPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default BusinessPage;

export const pageQuery = graphql`
  query BusinessPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
